@import './../../scss/config.scss';

.closeButtonLink {
  color: $text;
}

.modal-body {
  &.text-wrapper {
    padding: 20px 40px;
  }
}
