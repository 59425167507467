@import '../../scss/config';

.rec-resources-container {
  .label-text {
    width: rem(140px);
    max-width: rem(140px);
  }

  // .form-control {
  //   width: rem(210px);
  //   max-width: rem(210px);
  // }

  .view-more-button {
    border: none;
    background: transparent;
    color: $placeholder;
    font-size: rem(20px);
    text-align: right;
  }

  div[class*='-container'] {
    width: rem(210px);
    max-width: rem(210px);
    padding: 0;
  }

  .currency-input {
    width: rem(210px);
    max-width: rem(210px);
    padding: 0;
  }

  .housing-options-search {
    .search-icon {
      color: $placeholder;
    }

    .form-control {
      font-size: rem(14px);
    }

    ::placeholder {
      text-align: start;
    }
  }
}
