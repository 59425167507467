@import '../../scss/config';

.currency-input {
  .input-group-text {
    border: 1px solid $inputlines;
    border-style: solid none solid solid;
    border-radius: 4px;
    background-color: $white;
    color: $inputlines;
  }

  .form-control {
    border: 1px solid $inputlines;
    border-style: solid solid solid none;
    border-radius: 4px;
    background-color: $white;
  }
}
