@import '../../scss/config';

.search-bar {
  .form-control {
    font-size: 14px;
  }

  .search-button {
    border: 1px solid $cobalt !important;
    background-color: $cobalt !important;
    color: $white !important;
    font-size: 14px;
  }

  .search-icon {
    color: $nobel;
  }

  .exit-button {
    border: 1px solid #8eb3c9 !important;
    border-left: 0 !important;
    background-color: #ffffff !important;
    font-size: 16px;
  }

  .search-icon-button {
    border-right: 0 !important;
  }
}
