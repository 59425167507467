@import '../../../scss/config';

.modal-content {
  opacity: 0.95;

  ::placeholder {
    color: $white;
    text-align: center;
  }
}

.GSRadioButton[type='radio'] {
  opacity: 0;
}

.radioItem label:before {
  content: '';
  display: inline-block;
  vertical-align: -3px;
  width: 21px;
  height: 21px;
  margin-right: 9px;
  padding: 0.13em;
  border: 2px solid $white;
  border-radius: 100%;
  background-color: transparent;
  background-clip: content-box;
}

.radioItem label {
  margin-right: 1em;
}

.radioItem input:hover + label:before {
  border-color: $white;
}

.radioItem input:checked + label:before {
  border-color: $white;
  background-color: $white;
}

.textField {
  border-width: 0;
  border-bottom: 1px solid $white;
  background-color: transparent;
  color: $white;
}

.progress {
  background-color: rgba(255, 255, 255, 0.205) !important;
}

.buttonGroup {
  position: fixed;
  bottom: 10px;
  // right: 20px;
  background-color: rgba($color: #004266, $alpha: 0.9);
}
