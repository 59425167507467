// scss-docs-start color-variables
$navy: #004266;
$royal: #00588f;
$cobalt: #0071b8;
$pacific: #008ae0;
$dodger: #0aa1ff;
$summer_sky: #30b1ff;
$maya: #5cc0ff;
$sky: #85d0ff;
$lightblue: #b7e3ff;
$red: #f44336;
$orange: #fd7e14;
$yellow: #e6af2e;
$green: #4caf50;
$darkgreen: #008859;
$white: #ffffff;
$lightgray: #f8fcfe;
$nobel: #979797;
$mediumgray: #9fbbcc;
$darkgray: #697588;
$dustygray: #999999;
$black: #000d14;
$swamp: #000d14;

// scss-docs-start colors-map
$colors: (
  'navy': $navy,
  'royal': $royal,
  'cobalt': $cobalt,
  'lightblue': $lightblue,
  'red': $red,
  'orange': $orange,
  'yellow': $yellow,
  'green': $green,
  'darkgreen': $darkgreen,
  'sky': $sky,
  'white': $white,
  'lightgray': $lightgray,
  'mediumgray': $mediumgray,
  'darkgray': $darkgray,
  'black': $swamp,
  'dustygray': $dustygray,
);

// scss-docs-start theme-color-variables
$primary: $cobalt;
$secondary: $royal;
$availability: $green;
$headerfooterbg: $navy;
$favorites: $yellow;
$waitlist: $red;
$banner: $sky;
$lightblue: $lightblue;
$pagebg: $lightgray;
$inputlines: $mediumgray;
$placeholder: $darkgray;
$text: $swamp;
$textlink: $royal;
$textdisabled: $dustygray;
$error: $red;
$lightgreen: $green;

// fusv-disable
$navy-100: tint-color($navy, 80%);
$navy-200: tint-color($navy, 60%);
$navy-300: tint-color($navy, 40%);
$navy-400: tint-color($navy, 20%);
$navy-500: $navy;
$navy-600: shade-color($navy, 20%);
$navy-700: shade-color($navy, 40%);
$navy-800: shade-color($navy, 60%);
$navy-900: shade-color($navy, 80%);

$royal-100: tint-color($royal, 80%);
$royal-200: tint-color($royal, 60%);
$royal-300: tint-color($royal, 40%);
$royal-400: tint-color($royal, 20%);
$royal-500: $royal;
$royal-600: shade-color($royal, 20%);
$royal-700: shade-color($royal, 40%);
$royal-800: shade-color($royal, 60%);
$royal-900: shade-color($royal, 80%);

$cobalt-100: tint-color($cobalt, 80%);
$cobalt-200: tint-color($cobalt, 60%);
$cobalt-300: tint-color($cobalt, 40%);
$cobalt-400: tint-color($cobalt, 20%);
$cobalt-500: $cobalt;
$cobalt-600: shade-color($cobalt, 20%);
$cobalt-700: shade-color($cobalt, 40%);
$cobalt-800: shade-color($cobalt, 60%);
$cobalt-900: shade-color($cobalt, 80%);

$lightblue-100: tint-color($lightblue, 80%);
$lightblue-200: tint-color($lightblue, 60%);
$lightblue-300: tint-color($lightblue, 40%);
$lightblue-400: tint-color($lightblue, 20%);
$lightblue-500: $lightblue;
$lightblue-600: shade-color($lightblue, 20%);
$lightblue-700: shade-color($lightblue, 40%);
$lightblue-800: shade-color($lightblue, 60%);
$lightblue-900: shade-color($lightblue, 80%);

$red-100: tint-color($red, 80%);
$red-200: tint-color($red, 60%);
$red-300: tint-color($red, 40%);
$red-400: tint-color($red, 20%);
$red-500: $red;
$red-600: shade-color($red, 20%);
$red-700: shade-color($red, 40%);
$red-800: shade-color($red, 60%);
$red-900: shade-color($red, 80%);

$orange-100: tint-color($orange, 80%);
$orange-200: tint-color($orange, 60%);
$orange-300: tint-color($orange, 40%);
$orange-400: tint-color($orange, 20%);
$orange-500: $orange;
$orange-600: shade-color($orange, 20%);
$orange-700: shade-color($orange, 40%);
$orange-800: shade-color($orange, 60%);
$orange-900: shade-color($orange, 80%);

$yellow-100: tint-color($yellow, 80%);
$yellow-200: tint-color($yellow, 60%);
$yellow-300: tint-color($yellow, 40%);
$yellow-400: tint-color($yellow, 20%);
$yellow-500: $yellow;
$yellow-600: shade-color($yellow, 20%);
$yellow-700: shade-color($yellow, 40%);
$yellow-800: shade-color($yellow, 60%);
$yellow-900: shade-color($yellow, 80%);

$green-100: tint-color($green, 80%);
$green-200: tint-color($green, 60%);
$green-300: tint-color($green, 40%);
$green-400: tint-color($green, 20%);
$green-500: $green;
$green-600: shade-color($green, 20%);
$green-700: shade-color($green, 40%);
$green-800: shade-color($green, 60%);
$green-900: shade-color($green, 80%);

$sky-100: tint-color($sky, 80%);
$sky-200: tint-color($sky, 60%);
$sky-300: tint-color($sky, 40%);
$sky-400: tint-color($sky, 20%);
$sky-500: $sky;
$sky-600: shade-color($sky, 20%);
$sky-700: shade-color($sky, 40%);
$sky-800: shade-color($sky, 60%);
$sky-900: shade-color($sky, 80%);

$white-100: tint-color($white, 80%);
$white-200: tint-color($white, 60%);
$white-300: tint-color($white, 40%);
$white-400: tint-color($white, 20%);
$white-500: $white;
$white-600: shade-color($white, 20%);
$white-700: shade-color($white, 40%);
$white-800: shade-color($white, 60%);
$white-900: shade-color($white, 80%);
// fusv-enable
