@import '../../scss/config';

.mobile-basic-filter-container {
  .view-options-button {
    width: 133px;
    height: 40px;
    border: none;
    border-radius: 2px;
    background-color: $secondary;
    color: $white;
    font-size: rem(16px);
  }

  .more-filters-button {
    box-sizing: border-box;
    width: 100px;
    height: 40px;
    border: 1px solid $secondary;
    border-radius: 2px;
    background-color: $pagebg;
    color: $secondary;
    font-size: rem(16px);

    &:hover {
      background-color: $secondary;
      color: $white;
    }
  }
  @media screen and (min-width: 325px) {
    .saved-search-button-container {
      justify-content: center;
    }
  }
}
