@import '../../scss/config';

.btn-close {
  width: 20px !important;
  height: 30px !important;
  color: $black !important;
  font-weight: 300;
  font-size: 30px;
  line-height: 30px;
  letter-spacing: 0;
}

.checkout-label {
  font-weight: 600;
  font-size: 14px !important;
}

.digital-product {
  box-sizing: border-box;
  border: 1px solid #9fbbcc;
  border-radius: 4px;
  background-color: #f8fcfe;
  font-weight: 600;
  font-size: 14px !important;

  .qty-input {
    width: 80px;
    height: 36px;
  }
}

.digital-product-error {
  color: $red;
  font-weight: 600;
  font-size: 14px;
}
