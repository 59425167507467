@import '../../scss/config';

.product-card {
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  border-radius: 4px;
  background-color: $white;

  box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.3);

  .product-image-wrapper {
    width: 200px;
  }

  .product-title {
    display: -webkit-box;
    overflow: hidden;
    max-height: 50px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .product-desc {
    display: -webkit-box;
    overflow: hidden;
    height: 125px;
    max-height: 125px;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }

  .order-products {
    width: 135px;
    max-width: 135px;
    @media only screen and (max-width: 575px) {
      width: 100%;
      max-width: 100%;
    }
  }

  .content-type-container {
    width: 40px;
  }
}

.recommended-border {
  border: 2px solid $navy;
}
