@import '../../scss/config';

.resident-characteristics-dropdown {
  min-width: 6rem;
  max-width: 12rem;
}

.payment-types-dropdown {
  min-width: 6rem;
  max-width: 13rem;
}

.search-input {
  min-width: 17rem;
}

.only-show-avail {
  margin-top: rem(10px);
  font-size: 14px;
  cursor: pointer;
  padding: 0 !important;
}

.view-options {
  width: rem(140px);
}

.react-tiny-popover-container {
  z-index: 1000;
}

.more-filters-button {
  box-sizing: border-box;
  width: 153px;
  height: 37px;
  border: 1px solid $secondary;
  border-radius: 2px;
  background-color: $secondary;
  color: $pagebg;
  font-weight: 600;
  font-size: rem(16px);

  &:hover {
    border: 1px solid $secondary;
    background-color: $pagebg;
    color: $secondary;
  }
}

.clear-filters-button {
  box-sizing: border-box;
  width: 153px;
  height: 37px;
  border: 1px solid $secondary;
  border-radius: 2px;
  background-color: $pagebg;
  color: $secondary;
  font-weight: 600;
  font-size: rem(16px);

  &:hover {
    background-color: $secondary;
    color: $white;
  }
}
