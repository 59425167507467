.navbar-toggler {
  border: none !important;
}
$navbar-dark-color: rgba($white, 1);

.offcanvas {
  width: 100%;
  height: 100%;
  opacity: 0.9;
}

$offcanvas-horizontal-width: 100%;
$offcanvas-vertical-height: 100%;

.offcanvas-start {
  left: 100%;
  width: 100%;
}

.offcanvas-end {
  right: 100%;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .mainNav {
    color: #ffffff !important;
    font-size: xx-large;
  }
}
@media only screen and (max-width: 767px) {
  .mainFooter {
    text-align: center;
  }
}
