@import '../../scss/config';

.resource-card {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-width: 350px;
  height: 100%;
  max-height: 400px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 4px;
  background-color: $white;
  box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.3);

  .bottom-right {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  .resource-title {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .resource-desc {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
