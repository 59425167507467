$spacer: 1rem;

@use 'bootstrap/scss/bootstrap'
  with(
    $theme-colors: (
      'primary': $primary,
      'secondary': $secondary,
      'availability': $availability,
      'headerfooterbg': $headerfooterbg,
      'favorites': $favorites,
      'waitlist': $waitlist,
      'white': $white,
      'banner': $banner,
      'lightblue': $lightblue,
      'pagebg': $pagebg,
      'inputlines': $inputlines,
      'placeholder': $placeholder,
      'text': $text,
      'black': $black,
      'navy': $navy,
    ),

    $spacers: (
      0: 0,
      1: $spacer * 0.25,
      2: $spacer * 0.5,
      3: $spacer,
      4: $spacer * 1.25,
      // 20px
      5: $spacer * 2.5,
      // 40px
      6: $spacer * 4,
      7: $spacer * 5,
      8: $spacer * 6,
    ),

    $btn-close-color: $black,
    $btn-close-opacity: 1,
    $border-radius: 10px,
    $border-radius-lg: 20px,
    $component-active-bg: $secondary,
    $enable-negative-margins: true,
    $form-check-input-border-radius: 2px,
    $form-check-input-checked-bg-image:
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='#FFFFFF' d='M 7 1 l -4 4 l -2 -2 L 0 4.26 l 2.974 2.99 L 8 2.193 z'/%3e%3c/svg%3e"),
    $input-border-color: $inputlines,
    $input-border-radius: 4px,
    $input-btn-focus-box-shadow: none,
    $btn-border-radius: 4px,
    $btn-close-width: 1.5em
  );
