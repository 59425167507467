@import '../../scss/config';

.no-underline {
  a:link {
    text-decoration: none;
  }

  a:visited {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  a:active {
    text-decoration: none;
  }
}

.category {
  font-size: 14px;
}

.audience {
  font-size: 14px;
}

.sort-by {
  font-size: 14px;
}

.sort {
  width: 60px;
}
