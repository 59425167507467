@import '../../scss/config';

.favorite-button-bg-white {
  background-color: $pagebg;
}

.favorite-button-bg-gray {
  background-color: rgba(159, 187, 204, 0);
}

.add-to-favorites {
  min-width: 150px;
  border: none;
  border-radius: 50px !important;
  background-color: transparent;
  font-size: 14px !important;
  text-decoration: none;

  &.shadow {
    box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.3) !important;
  }
}
