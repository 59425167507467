@import 'config';
@import 'nav';
@import 'bootstrap';

html {
  font-size: 16px;
}

html,
body {
  height: 100%;

  /* Removes higlight on tap on mobile devices. */
  -webkit-tap-highlight-color: transparent;
}

body {
  overflow-y: auto;
  margin: 0;
  background-color: $pagebg;
  font-family: 'Open Sans', sans-serif;
  /* Disables "double tap to zoom in" on mobile devices. */
  /* https://stackoverflow.com/questions/46167604/iphone-html-disable-double-tap-to-zoom */
  touch-action: manipulation;
}

a {
  color: $textlink;
}

.regular-link {
  color: $textlink;
  text-decoration: underline !important;
}

.svg-inline--fa {
  margin-right: 0.5em;
}

.required::after {
  content: '*';
  color: $red;
  font-weight: 600;
  font-size: 20px;
}

.always-display-scrollbar {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

.changeCursor {
  cursor: pointer;
}

.checkbox-input {
  float: left;
}

.checkbox-label {
  display: block;
  padding-left: 20px;
}
