@import '../../scss/config';

.rh-info-window {
  border: 1px solid $mediumgray;
  box-shadow: 1px 2px 4px 0 rgba(0, 13, 20, 0.3);

  .rh-image {
    width: 100%;
    min-width: rem(90px);
    max-width: rem(135px);
    height: rem(116px);
    min-height: rem(116px);
    max-height: rem(116px);
  }

  .bottom-line {
    border-width: rem(1px);
    border-style: none none solid;
    border-color: $inputlines;
  }
}

.rh-info-window-selected-bg {
  background-color: rgba(159, 187, 204, 0.3);
}
